import { observable, action } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';

import RootModel from './RootModel';
import PopUpModel from './PopUpModel';
import FileBinModel from './FileBinModel';
import UpdateModel from './UpdateModel';
import DocPreviewModel from './DocPreviewModel';

import { DEAL_ID, SECTION } from '../constants/api';
import fetchData from '../Services/fetchData';
import Helper from '../Helpers/helper';

class FileManagerModel {
  @observable isPreviewShown = false;

  @action addOneFile(file, destination, subSectionId, source, parentDestination, parentSectionId) {
    if (DocPreviewModel.activeDocData && DocPreviewModel.fullPreview) {
      DocPreviewModel.updateNav(source, file);
    }

    let finishDest = destination;
    let finishParentDestination = parentDestination;
    let finishParentSectionId = parentSectionId;

    if (destination === 'FileBinFiles' || (SECTION && SECTION.length)) {
      if (file.order) delete file.order;
      if (file.month) delete file.month;
      if (file.year) delete file.year;

      file.parent_id = file.folderId ? file.folderId : FileBinModel.activeFolderId;
      if (file.folderId) delete file.folderId;

      RootModel['FileBinFiles'] = [...RootModel['FileBinFiles'], file];
    } else {
      if (destination === 'BanksSubSections') {
        const newDestDetails = Helper.getBankSubSectionInfo(subSectionId);
        finishDest = newDestDetails.dest;
        finishParentDestination = newDestDetails.parentDest;
        finishParentSectionId = newDestDetails.parentSectionId;
      }

      let startDest = source ? source.startDest : '';
      RootModel.updateSectionFiles(
        file,
        finishDest,
        subSectionId,
        startDest,
        finishParentDestination,
        finishParentSectionId,
      );

      if (finishDest === 'Banks') {
        RootModel.sortFilesByDate(subSectionId);
      }
    }

    if (source) {
      UpdateModel.updateDocumentsPlace(
        file,
        finishDest,
        subSectionId,
        source,
        finishParentDestination,
        finishParentSectionId,
      );
    }
    window.parent.postMessage({ hasChanged: true }, '*');
  }

  @action moveFileFromFileBinToSection(file, sectionId) {
    const bankFiles = RootModel.Banks.find(bank => bank.id === sectionId).files;

    bankFiles.push({
      ...file,
      name: file.title,
    });
    RootModel.FileBinFiles = RootModel.FileBinFiles.filter(fileBin => fileBin.id !== file.id);

    DocPreviewModel.togglePreview('close');
  }

  @action handleSubsectionCollapse(e, id, destination) {
    let newFiles = RootModel[destination].map(subsection => {
      if (subsection.id === id) {
        subsection.isCollapsed = !subsection.isCollapsed;
      }
      return subsection;
    });

    const currentBank = RootModel.Banks.find(bank => bank.id === id);
    const currentSubSection = e.target.parentElement.parentElement.parentElement;

    if (currentBank && currentBank.files.length === 0 && currentSubSection.classList.contains('collapsed')) {
      currentBank.details.forEach(detail => {
        this.getDocumentsForParent(detail.id, 'BankAccountDetail', currentBank.id);
      });
    }

    RootModel[destination] = newFiles;
  }

  @action sortFilesByIndex(dest) {
    const newSection = RootModel[dest].map(subSection => {
      const sortedFiles = subSection.files.map((file, index) => {
        file.order = index + 1;
        return file;
      });

      subSection.files = sortedFiles;
      return subSection;
    });

    RootModel[dest] = newSection;
  }

  @action handleClickOnFile(e) {
    const isItDeletBtn =
      e.target.parentNode.classList.contains('delete-btn') || e.target.classList.contains('delete-btn');
    if (isItDeletBtn) return;

    this.isPreviewShown = true;
  }

  @action closePreview() {
    //to preview model?
    this.isPreviewShown = false;
  }

  @action submitCreateBankAccountForm(bankName, account_number) {
    if (!bankName || !account_number) {
      NotificationManager.error('All fields must be filled');
      return null;
    } else if (+account_number !== +account_number) {
      NotificationManager.error('Accont number should be a number');
      return null;
    }

    const newBank = {
      name: `${bankName}`,
      deal_id: DEAL_ID,
      account_number: account_number,
    };

    const data = {
      ...newBank,
      deal_id: DEAL_ID, //"49006"
    };

    fetchData('bank_accounts/create', data)
      .then(res => res.json())
      .then(
        action(res => {
          NotificationManager.success(`New bank account - "${newBank.name.split(' | ')[0]}" has been created!`);
          RootModel.getBanks();
          PopUpModel.closeAll();
        }),
      )
      .catch(err => {
        NotificationManager.erorr(err.error);
      });
  }

  @action submitUpdateBankAccountForm(bankName, account_number, accountId) {
    if (!bankName || !account_number) {
      NotificationManager.error('All fields must be filled');
      return null;
    } else if (+account_number !== +account_number) {
      NotificationManager.error('Accont number should be a number');
      return null;
    }

    const newBank = {
      name: `${bankName}`,
      deal_id: DEAL_ID,
      account_number: account_number,
      id: accountId,
    };

    const data = {
      ...newBank,
      deal_id: DEAL_ID, //"49006"
    };

    fetchData('bank_accounts/update', data)
      .then(res => res.json())
      .then(
        action(res => {
          NotificationManager.success(`The bank account - "${newBank.name.split(' | ')[0]}" has been updated!`);
          RootModel.getBanks();
          PopUpModel.closeAll();
        }),
      )
      .catch(err => {
        NotificationManager.error(err.error);
      });
  }

  @action deleteBankAccount(subSectionData, dest) {
    let data = {
      id: subSectionData.id,
    };

    fetchData('bank_accounts/destroy', data)
      .then(res => res.json())
      .then(
        action(res => {
          let newSection = RootModel[dest].filter(subSection => Number(subSection.id) !== Number(res));

          RootModel[dest] = newSection;
          NotificationManager.success(`${subSectionData.name} has been successfully deleted!`);
          window.parent.postMessage({ hasChanged: true }, '*');
        }),
      )
      .catch(err => {
        NotificationManager.erorr(err.error);
      });
  }
}

const model = new FileManagerModel();
export default model;
