import React, { Component } from 'react';
import * as mobx from 'mobx';
import { Droppable } from 'react-beautiful-dnd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { observer } from 'mobx-react';

import FileBinModel from '../../Models/FileBinModel';
import RootModel from '../../Models/RootModel';
import DocPreviewModel from '../../Models/DocPreviewModel';
import TrashModel from '../../Models/TrashModel';
import { PREVIEW_UPLOAD_INFO, VIEW_FILE } from '../../constants/api';
import SingleFileUploadSection from './SingleFileUploadSection';
import '@react-pdf-viewer/core/lib/styles/index.css';

@observer
class FileManagerPreviewFile extends Component {
  constructor(props) {
    super(props);

    this.disposer = null;
    this.uploadInputRef = React.createRef();

    this.state = {
      isInTrash: false,
      fileBinFiles: [],
      activeFolderId: 'FileBinFiles',
      bank: {},
    };
  }

  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const activeFolderId = FileBinModel.activeFolderId;

      const fileBinFiles = VIEW_FILE
          ? [...RootModel.FileBinFiles].filter(file => {
              if (file.parent_id) {
                return file.parent_id.toString() === activeFolderId.toString();
              }
            })
          : [],
        activeDocData = DocPreviewModel.activeDocData,
        isInTrash = TrashModel.determineIsInTrash(),
        bank = RootModel.Banks.find(x => x.id === PREVIEW_UPLOAD_INFO.id);
      this.setState({ activeFolderId, fileBinFiles, activeDocData, isInTrash, bank });
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { fileBinFiles, bank } = this.state;
    const file = fileBinFiles.length ? fileBinFiles[0] : null;

    if (!file) {
      if (!PREVIEW_UPLOAD_INFO.id) {
        return null;
      }

      return (
        <div className="single-file-upload">
          {' '}
          <Droppable droppableId={'FileBinFiles'}>
            {provided => <SingleFileUploadSection provided={provided} data={bank} />}
          </Droppable>
        </div>
      );
    }

    return (
      <div className="PDFObject-wrapper">
          <iframe
              title={file.title}
              src={file.url}
              width="100%"
              height="100%"
              style={{border: 'none', minHeight: '500px'}}
          />
      </div>
    );
  }
}

export default FileManagerPreviewFile;
