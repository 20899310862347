import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import { v4 as uuid } from 'uuid';

import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import FileBinModel from '../../../Models/FileBinModel';
import UploadModel from '../../../Models/loadModels/UploadModel';
import PopUpModel from '../../../Models/PopUpModel';
import OtherDealsModel from '../../../Models/OtherDealsModel';

import EditFolderNameForm from '../../forms/EditFolderNameForm';
import EditBtn from '../../buttons/EditBtn';
import Helper from '../../../Helpers/helper';
import arrowUpIcon from '../../../Images/arrow-up.png';

@observer
class FoldersBreadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.disposer = null;
    this.uploadInputRef = React.createRef();

    this.state = {
      fileBinFiles: [],
      activeFolderId: 'FileBinFiles',
    };
  }

  onDragOver = e => {
    e.preventDefault();
  };

  onDrop = e => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    UploadModel.handleFilesUpload(files, 'FileBinFiles', FileBinModel.activeFolderId); //dest must be not right
  };

  openFileDialog = e => {
    this.uploadInputRef.current.click();
  };

  openEdit() {
    if (this.state.activeFolderId) {
      let currFolder = FileBinModel.FileBinFolders.find(folder => {
        if (folder.id && this.state.activeFolderId)
          return folder.id.toString() === this.state.activeFolderId.toString();
      });
      if (currFolder) FileBinModel.toggleEditFolder(currFolder);
    }
  }

  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const activeFolderId = FileBinModel.activeFolderId,
        otherDeals = OtherDealsModel.otherDeals,
        { formatedDealsForPreview } = OtherDealsModel,
        { trashId, isInTrash } = TrashModel;

      const inOtherDeals = OtherDealsModel.determineInOtherDeals();
      const fileBinFiles = [...RootModel.FileBinFiles].filter(file => {
          if (file.parent_id) {
            return file.parent_id.toString() === activeFolderId.toString();
          }
        }),
        foldersRoute = FileBinModel.foldersRoute;

      this.setState({
        activeFolderId,
        foldersRoute,
        formatedDealsForPreview,
        fileBinFiles,
        trashId,
        isInTrash,
        otherDeals,
        inOtherDeals,
      });
    });

    this.disposer = mobx.reaction(
      () => FileBinModel.folderNameChanging,
      folderNameChanging => {
        this.setState({ folderNameChanging });
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  togglePopUp(value) {
    PopUpModel.open(value);
  }

  render() {
    const { activeFolderId, foldersRoute, trashId, inOtherDeals, folderNameChanging } = this.state;

    let shortenBreadcrumbs = Helper.shortenBreadcrumbs(foldersRoute);

    return (
      <div className="location" data-testid="folders-breadcrumbs">
        <span
          className={`return-btn ${activeFolderId === 'FileBinFiles' ? 'disabled' : ''}`}
          onClick={() => FileBinModel.setPreviousActiveFolder()}
          data-testid="return-btn"
        >
          <img src={arrowUpIcon} alt="arrowUp" />
        </span>

        {!inOtherDeals && (
          <span
            className="breadcrumb"
            onClick={e => FileBinModel.setActiveFolder('FileBinFiles')}
            data-testid="file-bin-breadcrumb"
          >
            File Bin
          </span>
        )}
        {inOtherDeals && (
          <span
            className="breadcrumb"
            onClick={e => FileBinModel.setActiveFolder('FileBinFiles')}
            data-testid="other-deals-breadcrumb"
          >
            Documents from Other Deals
          </span>
        )}

        {foldersRoute &&
          !inOtherDeals &&
          foldersRoute.map((folder, i) => {
            let previousActiveFolderId = i > 0 ? foldersRoute[i - 1].id : 'FileBinFiles';
            let displayEditIcon = i === foldersRoute.length - 1 && !folderNameChanging && !inOtherDeals;

            if (trashId.toString() !== activeFolderId.toString() && i === foldersRoute.length - 1 && !displayEditIcon) {
              return (
                <Fragment key={uuid()}>
                  <span className="divider">&nbsp;>&nbsp;</span>
                  <EditFolderNameForm
                    folder={FileBinModel.FileBinFolders.find(folder => folder.isEditModeOn)}
                    data-testid="edit-folder-name-form"
                  />
                </Fragment>
              );
            } else if (shortenBreadcrumbs && i === 1) {
              return (
                <span
                  key={uuid()}
                  onClick={() => FileBinModel.setActiveFolder(previousActiveFolderId)}
                  data-testid="shortened-breadcrumb"
                >
                  {' '}
                  &nbsp;>&nbsp; .........
                </span>
              );
            } else {
              return (
                <Fragment key={folder.id}>
                  {!inOtherDeals && (
                    <span className="divider" onClick={() => FileBinModel.setActiveFolder(previousActiveFolderId)}>
                      &nbsp;>&nbsp;
                    </span>
                  )}
                  <span
                    className="breadcrumb"
                    onClick={() => FileBinModel.setActiveFolder(folder.id)}
                    data-testid={`folder-breadcrumb-${folder.id}`}
                  >
                    {Helper.shortenName(folder.name, 34)}{' '}
                  </span>
                  {displayEditIcon && trashId.toString() !== activeFolderId.toString() && (
                    <EditBtn onClick={() => this.openEdit()} data-testid="edit-btn" />
                  )}
                </Fragment>
              );
            }
          })}
      </div>
    );
  }
}

export default FoldersBreadcrumbs;
