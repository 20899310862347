import { v4 as uuid } from 'uuid';
import RootModel from '../Models/RootModel';
import { getMonthReverce } from './convertData';

class Helper {
  shortenName(name, size) {
    size = size || 34;

    if (name.length > size) {
      if (name.indexOf('.') > -1) {
        name = name.split('.');
        let shortenLength = name[1].length + '... .'.length;
        name = name[0].slice(0, size - shortenLength) + '... .' + name[1];
      } else {
        name = name.slice(0, size) + '...';
      }
    }
    return name;
  }

  shortenBreadcrumbs(location) {
    if (location && typeof location !== 'string') {
      let names = location.map(folder => folder.name);
      names = names.join(' > ');
      if (names.length > 40) {
        return true;
      }
    }
    return false;
  }

  // banks
  isBankMonthToDate(dest, subSectionId) {
    let bank = RootModel.Banks.find(bank => bank.id.toString() === subSectionId.toString());
    return bank && bank.monthToDate;
  }

  getBankById(id) {
    for (let i = 0; i < RootModel.Banks.length; i++) {
      if (RootModel.Banks[i].id.toString() === id.toString()) {
        return RootModel.Banks[i];
      }
    }
    return null;
  }

  getBankSubSectionInfo(subSectionId) {
    for (let i = 0; i < RootModel.BanksSubSections.length; i++) {
      if (RootModel.BanksSubSections[i].id.toString() === subSectionId) {
        return RootModel.BanksSubSections[i];
      }
    }
  }

  getBankDocumentsDateId(subSectionId, month, year) {
    let currBank = this.getBankById(subSectionId);
    if (!currBank) return null;

    for (let i = 0; i < currBank.bankDetails.length; i++) {
      if (+currBank.bankDetails[i].month === getMonthReverce(month) && +currBank.bankDetails[i].year === year) {
        return currBank.bankDetails[i].id;
      }
    }

    return null;
  }

  // sections and files
  getSectionName(subSectionId) {
    const mainSections = ['Banks', 'SubDocs', 'Contracts', 'Stips', 'BanksSubSections'];

    for (let i = 0; i < mainSections.length; i++) {
      const section = mainSections[i];
      for (let j = 0; j < RootModel[section].length; j++) {
        const subSection = RootModel[section][j];
        if (subSection.id.toString() === subSectionId.toString()) {
          return section;
        }
      }
    }
    return null;
  }

  checkSectionIsDeprecated(subSectionId) {
    const mainSections = ['Banks', 'SubDocs', 'Contracts', 'Stips', 'BanksSubSections'];

    for (let i = 0; i < mainSections.length; i++) {
      const section = mainSections[i];
      for (let j = 0; j < RootModel[section].length; j++) {
        const subSection = RootModel[section][j];
        if (subSection.id.toString() === subSectionId.toString()) {
          return subSection.deprecated;
        }
      }
    }
    return false;
  }

  convertToFileObject(file, month, year) {
    let fileName = file.title || file.original_filename;
    let fileUrl = file.document_url || file.url;

    let splited = fileName.split(/[.|,]/g);
    if (splited.length > 1) {
      splited[splited.length - 1] = '.' + splited[splited.length - 1];
      fileName = splited.slice(0, splited.length - 1).join('') + splited[splited.length - 1];
    }

    let fileObj = {
      id: file.id || uuid(),
      url: fileUrl,
      type: fileName.split('.')[1] ? fileName.split('.')[1].toUpperCase() : null,
      title: fileName,
      isEditModeOn: false,
      document_type: file.document_type,
      isOtherDeal: file.isOtherDeal,
      mimeType: file.mime_type,
      created_at: file.created_at,
    };

    if (file.description) fileObj.description = file.description;
    if (month && year) {
      fileObj.month = month;
      fileObj.year = year;
    }
    if (file.submission_date) fileObj.submission_date = file.submission_date;

    return fileObj;
  }

  getSectionFiles(dest, subSectionId) {
    let subsectionFiles;
    if (dest !== 'FileBinFiles') {
      subsectionFiles = [...RootModel[dest]].find(
        subSection => subSection.id.toString() === subSectionId.toString(),
      ).files;
    } else {
      subsectionFiles = [...RootModel[dest]].filter(
        subSection => subSection.parent_id.toString() === subSectionId.toString(),
      );
    }

    return subsectionFiles;
  }

  findFile(source, draggableId) {
    let file = null,
      startDest = source.droppableId === 'FileBinFiles' ? 'FileBinFiles' : this.getSectionName(source.droppableId),
      data = RootModel[startDest];

    if (!data) return;

    for (let i = 0; i < data.length; i++) {
      if (startDest !== 'FileBinFiles' && data[i].id.toString() === source.droppableId.toString()) {
        file = data[i].files.find(file => file.id === draggableId);
        data[i].files = data[i].files.filter(file => file.id !== draggableId);
        RootModel.sortFilesByDate(data[i].id);
        break;
      } else if (startDest === 'FileBinFiles' && data[i].id.toString() === draggableId.toString()) {
        return data[i];
      }
    }

    return file;
  }

  isEmptySection(section) {
    for (let i = 0; i < section.length; i++) {
      let subSection = section[i];
      if (subSection.files && subSection.files.length > 0) return false;
    }
    return true;
  }

  formatSelectDate(date) {
    date = new Date(date);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return {
      month,
      year,
      onFile: false,
    };
  }
}

export default new Helper();
