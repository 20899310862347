import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import { v4 as uuid } from 'uuid';
import { Draggable } from 'react-beautiful-dnd';

import FileBlock from './FileBlock';
import OtherDealsModel from '../../Models/OtherDealsModel';
import Helper from '../../Helpers/helper';
import { formatDate } from '../../Helpers/convertData';

@observer
class OtherDealsFolder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.disposer = null;
  }

  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const { otherDealsDocuments } = OtherDealsModel;
      OtherDealsModel.determineInOtherDeals(true);

      this.setState({ otherDealsDocuments });
    });
  }

  componentWillUnmount() {
    this.disposer();
    OtherDealsModel.determineInOtherDeals(false);
  }

  render() {
    const { otherDealsDocuments } = this.state;
    if (!otherDealsDocuments) return null;

    return (
      <div className="other-deal-folder" data-testid="other-deals-folder">
        {otherDealsDocuments.map((deal, index) => {
          if (deal.documents.length) {
            return (
              <div key={uuid()} data-testid={`other-deals-date-section-${deal.id}`}>
                <p className="other-deals-date" data-testid={`other-deals-date-${deal.id}`}>
                  {formatDate(deal.submission_date)}
                </p>
                <div className="files-section" data-testid={`other-deals-files-section-${deal.id}`}>
                  {deal.documents.map(doc => {
                    let file = Helper.convertToFileObject(doc);
                    return (
                      <Draggable draggableId={file.id} index={index} key={file.id}>
                        {provided => (
                          <FileBlock
                            provided={provided}
                            key={file.id}
                            data={file}
                            isPreviewed={false}
                            data-testid={`file-block-${file.id}`}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
}

export default OtherDealsFolder;
