import { v4 as uuid } from 'uuid';
import Helper from '../Helpers/helper';

export const Sections = {
  //make it match ids
  Banks: ['Month To Date'],
  SubDocs: ['App', 'Credit', 'Clear', 'Sos'],
  Contracts: [{ Contracts: ['Contracts', 'Signed_contract_coj'] }, 'Invalid_Contracts', 'Other_Contracts', 'UCC'],
  Stips: [
    'Funding_vc',
    'Dl',
    'Tax_return',
    'Financials',
    'Lease',
    'Stip_Other',
    'Proof_of_ownership',
    'Articles_of_incorporation',
    'Accounts_receivables',
  ],
  FileBinFiles: [],
  'Other Contract Docs': ['other_contract_docs'],
};

export const SectionsOverrideForSave = [
  'General',
  'Signed_contract_coj',
  'Contracts',
  'Invalid_Contracts',
  'Other_Contracts',
  'UCC',
  'Funding_call',
  'Certificate',
];

export const BankDateToMonth = {
  title: 'Month to Date',
  name: 'Month to Date',
  accountNumber: false,
  id: uuid(),
  isDeletable: false,
  isCollapsed: false,
  monthToDate: true,
  files: [],
  subSections: [],
};

export const BankSubSectionStructure = [
  {
    title: 'Voided Check',
    name: 'Voided Check',
    dest: `Funding_vc`,
    id: uuid(),
    bankId: null,
    isDeletable: false,
    isCollapsed: false,
    hideFileCollapse: true,
    onlySingleFileAllowed: true,
    hideAllFileDownloads: true,
    files: [],
  },
];

export const SubDocStructure = [
  {
    title: 'Applications',
    name: 'App',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Credit',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Clear Reports',
    name: 'Clear',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'SOS',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
];

export const ContractStructure = [
  {
    title: 'Signed Contracts',
    name: 'signed_contract_coj',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'General',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Certificate',
    id: uuid(),
    name: 'Certificate',
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Funding call',
    id: uuid(),
    name: 'Funding_call',
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Invalid Contracts',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Other Contract Docs',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'UCC',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
];

export const StipStructure = [
  {
    title: 'Voided Check',
    name: 'Funding_vc',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
    deprecated: true,
  },
  {
    title: 'Drivers Licence',
    id: uuid(),
    name: 'DL',
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Tax Returns',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Financials',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Lease',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Other',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Proof of Ownership',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Articles of Incorporation',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
  {
    title: 'Accounts Receivables',
    id: uuid(),
    isCollapsed: false,
    files: [],
    otherDeals: [],
  },
];

export function getSubsectionMaps() {
  let allObjects = [BankDateToMonth, ...StipStructure, ...ContractStructure, ...SubDocStructure];
  let subSectionMap = {};
  for (let key in Sections) {
    let subs = key == 'Contracts' ? SectionsOverrideForSave : Sections[key];
    subs.forEach(item => {
      let subSectionObject = allObjects.find(x =>
        x.name ? x.name.toLowerCase() === item.toLowerCase() : x.title.toLowerCase() === item.toLowerCase(),
      );
      subSectionMap[item.toLowerCase()] = {
        section: key,
        subSection: item,
        info: subSectionObject,
      };
    });
  }
  return subSectionMap;
}

export const FileBinFileStructure = [];

export const FileBinFolderStructure = [];
