import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Document, Page, pdfjs } from 'react-pdf';
import DeleteBtn from '../buttons/DeleteBtn';

import PopUpModel from '../../Models/PopUpModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';
import DocPreviewModel from '../../Models/DocPreviewModel';
import OtherDealsModel from '../../Models/OtherDealsModel';

import DownloadBtn from '../buttons/DownloadBtn';

import Helper from '../../Helpers/helper';

@observer
class FileBlock extends Component {
  state = {
    passwordProtected: false,
  };

  openFile = e => {
    const { data } = this.props;
    if (e.target.className.indexOf('delete-btn') === -1) {
      DocPreviewModel.togglePreview('open', this.props.data);
      DocPreviewModel.setCurrentLocation('FileBinFiles');
    }
  };

  openFullFilePreview() {
    DocPreviewModel.setCurrentLocation('FileBinFiles');
    DocPreviewModel.setDocData({ data: this.props.data, subSectionId: this.props.subSectionId });
    DocPreviewModel.toggleFullPreview();
  }

  handleProtectedFile = () => {
    this.setState({ passwordProtected: true });
  };

  contentToRender = () => {
    const { data, isPreviewed } = this.props;

    const documentTitleComponent = <span className="title">{Helper.shortenName(data.title, 17)}</span>;

    const { passwordProtected } = this.state;
    const isImage = data.mimeType && data.mimeType.startsWith('image');

    if (isImage) {
      return (
        <div className={'previewed-document-img'}>
          <img src={data.url} alt="previewed document" />
          {documentTitleComponent}
        </div>
      );
    }

    if (passwordProtected) {
      return (
        <div className="document">
          <div className="protected-file">
            <span className="protected-file-message">password-protected</span>
          </div>

          {documentTitleComponent}
        </div>
      );
    }

    return (
      <Document
        className={`document ${isPreviewed ? 'previewed-document' : ''}`}
        file={data.url}
        loading={<div className="pdf-loading" />}
        error={documentTitleComponent}
        onPassword={this.handleProtectedFile}
      >
        <Page width={160} className="page" loading="" pageNumber={1} />
        {documentTitleComponent}
      </Document>
    );
  };

  render() {
    const { data, provided, isInTrash, inOtherDeals } = this.props;

    const deletableData = {
      fileData: data,
      dest: 'FileBinFiles',
      subSectionId: null,
    };

    let draggableProps = provided ? { ...provided.draggableProps, ...provided.dragHandleProps } : {};
    let providedRef = provided ? provided.innerRef : null;

    return (
      <div
        title={data.title}
        className="file"
        onClick={e => this.openFile(e)}
        {...draggableProps}
        onDoubleClick={() => this.openFullFilePreview()}
        ref={providedRef}
        data-testid="file-block"
      >
        {this.contentToRender()}
        <DownloadBtn
          onClick={e => DownloadModel.downloadFile(data)}
          className={isInTrash ? 'trash-download' : ''}
          data-testid="download-btn"
        />
        {!isInTrash && !OtherDealsModel.inOtherDeals && (
          <DeleteBtn onClick={e => PopUpModel.open('deleteFileConfirmation', deletableData)} data-testid="delete-btn" />
        )}
      </div>
    );
  }
}

export default FileBlock;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
