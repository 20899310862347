import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Document, Page, pdfjs } from 'react-pdf';

import DownloadModel from '../../Models/loadModels/DownloadModel';
import DocPreviewModel from '../../Models/DocPreviewModel';
import Helper from '../../Helpers/helper';
import DownloadBtn from '../buttons/DownloadBtn';

@observer
class FileBlockCut extends Component {
  state = {
    passwordProtected: false,
  };

  openFullFilePreview(e) {
    if (e.target.className.indexOf('thread-doc-download') > -1 || e.target.alt === 'download') {
      // download button was clicked
      return;
    }

    DocPreviewModel.setCurrentLocation('FileBinFiles');
    DocPreviewModel.setDocData({ data: this.props.data, subSectionId: this.props.subSectionId });
  }

  handleProtectedFile = () => {
    this.setState({ passwordProtected: true });
  };

  contentToRender = () => {
    const { data, isPreviewed } = this.props;
    const { passwordProtected } = this.state;

    let documentTitleComponent = <span className="title">{Helper.shortenName(data.title, 17)}</span>;
    if (data.document_type === 'Credit')
      documentTitleComponent = (
        <span className="title" style={{ textAlign: 'center' }}>
          {new Date(data.created_at).toLocaleDateString('en-US')}
        </span>
      );

    const isImage = data.mimeType && data.mimeType.startsWith('image');

    if (isImage) {
      return (
        <div className={'previewed-document-img'}>
          <img src={data.url} alt="previewed document" />
          {documentTitleComponent}
        </div>
      );
    }

    if (passwordProtected) {
      return (
        <div className="document">
          <div className="protected-file">
            <span className="protected-file-message">password-protected</span>
          </div>

          {documentTitleComponent}
        </div>
      );
    }

    return (
      <Document
        className={`document ${isPreviewed ? 'previewed-document' : ''}`}
        file={data.url}
        loading={<div className="pdf-loading" />}
        error={documentTitleComponent}
        onPassword={this.handleProtectedFile}
      >
        <Page width={160} className="page" loading="" pageNumber={1} />
        {documentTitleComponent}
      </Document>
    );
  };

  render() {
    const { data, fileClassName, hideDownloadBtn } = this.props;

    return (
      <div
        title={data.title}
        className={fileClassName ? fileClassName : 'file'}
        onClick={e => (hideDownloadBtn ? DownloadModel.openInNewWindow(data) : this.openFullFilePreview(e))}
      >
        {this.contentToRender()}
        {!hideDownloadBtn ? (
          <DownloadBtn onClick={e => DownloadModel.openInNewWindow(data)} className="thread-doc-download" />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default FileBlockCut;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
