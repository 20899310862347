import React, { Component } from 'react';
import * as mobx from 'mobx';
import { v4 as uuid } from 'uuid';

import mammoth from 'mammoth';
import { observer } from 'mobx-react';

import FileBinModel from '../../Models/FileBinModel';
import OtherDealsModel from '../../Models/OtherDealsModel';
import Helper from '../../Helpers/helper';
import DocPreviewModel from '../../Models/DocPreviewModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';
import DownloadBtn from '../buttons/DownloadBtn';
import DeleteBtn from '../buttons/DeleteBtn';
import PrevBtn from '../buttons/PrevBtn';
import NextBtn from '../buttons/NextBtn';
import CloseBtn from '../buttons/CloseBtn';
import MovingSelect from './MovingSelect';
import DownloadFileMessage from './DownloadFileMessage';

@observer
class FullDocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.disposer = null;

    this.state = {
      selectedTools: false,
      isFirstFile: true,
      isLastFile: true,
      inOtherDeals: false,
      loadingDOCX: true,
    };
  }

  componentWillUnmount() {
    this.disposer();
  }

  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const { activeDocData } = DocPreviewModel,
        { isFirstFile } = DocPreviewModel,
        { isLastFile } = DocPreviewModel,
        { currentLocation } = DocPreviewModel,
        { activeFolderId } = FileBinModel,
        { folderId } = OtherDealsModel.otherDeals;

      let inOtherDeals = currentLocation === 'FileBinFiles' && activeFolderId.toString() === folderId.toString();
      let loadingDOCX = this.state.loading;
      if (activeDocData && activeDocData.type === 'DOCX') {
        let newURL = activeDocData.url;
        const jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', newURL, true);
        jsonFile.send();
        jsonFile.responseType = 'arraybuffer';
        jsonFile.onreadystatechange = () => {
          if (jsonFile.readyState === 4 && jsonFile.status === 200) {
            mammoth
              .convertToHtml({ arrayBuffer: jsonFile.response }, { includeDefaultStyleMap: true })
              .then(result => {
                const docEl = document.createElement('div');
                docEl.className = 'document-container';
                docEl.innerHTML = result.value;
                document.getElementById('docx').innerHTML = docEl.outerHTML;
              })
              .catch(a => {
                console.error(a);
              })
              .done();
          }
        };
        loadingDOCX = false;
      } else {
        loadingDOCX = true;
      }
      this.setState({ activeDocData, isFirstFile, isLastFile, inOtherDeals, loadingDOCX });
    });

    this.disposer = mobx.reaction(
      () => DocPreviewModel.selectedTools,
      selectedTools => {
        this.setState({ selectedTools });
      },
    );
  }

  render() {
    const { activeDocData, isFirstFile, isLastFile, loadingDOCX } = this.state;

    if (!activeDocData) return null;
    let isImage = activeDocData.type === 'PNG' || activeDocData.type === 'JPEG' || activeDocData.type === 'JPG';

    return (
      <div className="file-manager__preview file-manager__fullpreview">
        <div className="head-section" ref={node => (this.headSectionPreview = node)}>
          <div className="first-buttons-section">
            <MovingSelect />
            <h4 className="preview-file-title">{Helper.shortenName(activeDocData.title, 40)}</h4>
          </div>
          <div className="second-buttons-section">
            <DeleteBtn isPreview={true} onClick={() => DocPreviewModel.deleteCurrentFile()} />
            <DownloadBtn onClick={() => DownloadModel.downloadFile(activeDocData)} />
            <PrevBtn onClick={() => DocPreviewModel.getPrevFile()} disabled={isFirstFile} />
            <NextBtn onClick={() => DocPreviewModel.getNextFile()} disabled={isLastFile} />
            <CloseBtn onClick={() => DocPreviewModel.toggleFullPreview(activeDocData)} />
          </div>
        </div>

        <div className="wrapper" id="full-preview-wrapper">
          {activeDocData.type && isImage && <img src={activeDocData.url} alt={activeDocData.name} key={uuid()} />}
          {(!activeDocData.type || (activeDocData.type && activeDocData.type === 'PDF')) && (
            <div className="PDFObject-wrapper">
              <iframe
                title={activeDocData.title}
                src={activeDocData.url}
                width="100%"
                height="100%"
                style={{ border: 'none', minHeight: '600px' }}
              />
            </div>
          )}
          {activeDocData.type && activeDocData.type === 'DOCX' && (
            <div className="pg-viewer-wrapper">
              {loadingDOCX && (
                <div className="loading-container">
                  <span className="loading" />
                </div>
              )}
              {!loadingDOCX && <div id="docx" />}
            </div>
          )}
          {activeDocData.type && !isImage && activeDocData.type !== 'DOCX' && activeDocData.type !== 'PDF' && (
            <DownloadFileMessage />
          )}
        </div>
      </div>
    );
  }
}
export default FullDocumentPreview;
